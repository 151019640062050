import React from "react";
import {
  faBars,
  faUserTie,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faCogs,
  faShieldAlt,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../img/color1_text-logoname_transparent_background.png";
import OKPI from "./components/Kpi";
import ShiftSchedule from "./charts/Agents/ShiftSchedule";
import AgentCapability from "./charts/Agents/Agenetcc";
import OverallResponseTimeMetric from "./charts/Agents/OverallResponseTimeMetric";
import AgentCapabilityDonutChart from "./charts/Agents/Agenetcc";

const OodaAgents = () => {
  // Sample data for agents

  return (
    <div className="flex h-screen  bg-gray-900 ml-20">
      <header className="fixed top-0 left-0 right-0 z-50 flex justify-between p-4 shadow-md bg-gray-900 mb-4">
        <FontAwesomeIcon icon={faBars} className="text-xl" />
        <div className="text-xl font-semibold text-white text-left">Alarms</div>
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-16 w-auto" />
        </div>
      </header>
      <div className="flex-1 flex flex-col overflow-y-auto bg-gray-900 mt-20 p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          <OKPI
            title="Total Agents"
            value={25} // Example fake data
            icon={faUserTie} // Using a user icon for agents
            bgColor="bg-gradient-to-r from-blue-500 to-blue-700"
          />
          <OKPI
            title="Active Agents"
            value={18} // Example fake data
            icon={faShieldAlt} // Icon indicating active status
            bgColor="bg-gradient-to-r from-green-500 to-green-700"
          />
          <OKPI
            title="Offline Agents"
            value={5} // Example fake data
            icon={faExclamationCircle} // Warning icon for offline status
            bgColor="bg-gradient-to-r from-red-500 to-red-700"
          />
          <OKPI
            title="In-Mission Agents"
            value={2} // Example fake data
            icon={faUserTie} // Same icon can be used for in-mission
            bgColor="bg-gradient-to-r from-orange-500 to-orange-700"
          />
        </div>
        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <ShiftSchedule/>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <AgentCapabilityDonutChart />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <OverallResponseTimeMetric/>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
};

export default OodaAgents;
