import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

// Register the Chart.js plugins
Chart.register(ChartDataLabels);

const AgentCapabilityDonutChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // Internal agent data
  const agentData = [
    { category: 'Category 1' },
    { category: 'Category 1' },
    { category: 'Category 2' },
    { category: 'Category 3' },
    { category: 'Category 2' },
    { category: 'Category 3' },
    { category: 'Category 1' },
    { category: 'Category 1' },
  ];

  useEffect(() => {
    if (!agentData || agentData.length === 0) return;

    // Process agent capability data by category
    const categoryCount = { 'Category 1': 0, 'Category 2': 0, 'Category 3': 0 };
    agentData.forEach((agent) => {
      const category = agent.category || 'Unknown';
      if (categoryCount[category] !== undefined) {
        categoryCount[category] += 1;
      }
    });

    const categories = Object.keys(categoryCount);
    const counts = Object.values(categoryCount);

    setChartData({
      labels: categories,
      datasets: [
        {
          label: 'Agents',
          data: counts,
          backgroundColor: ['#FCA5A5', '#FDBA74', '#A7F3D0'], // Fixed light mode colors
          borderColor: '#E5E7EB',
          borderWidth: 3,
          hoverOffset: 15,
        },
      ],
    });
  }, []);

  // Format numbers for tooltips and datalabels
  const formatNumber = (num) => {
    return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num.toFixed(0);
  };

  return (
    <div
      className="p-6 rounded-lg  bg-gray-800 text-white h-64"
      style={{ width: '100%' }}
    >
      {/* Chart Title with Icon */}
      <h3 className="text-lg font-semibold mb-4 text-left">
        <FontAwesomeIcon icon={faUserShield} className="mr-2" />
        Agent Capability Distribution
      </h3>

      <div style={{ height: 'calc(100% - 40px)' }}>
        <Doughnut
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  color: '#E5E7EB', // White text for legend
                  font: {
                    size: 9,
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `${tooltipItem.label}: ${formatNumber(value)}`;
                  },
                },
                backgroundColor: '#374151', // Dark background for tooltip
                titleColor: '#E5E7EB',
                bodyColor: '#E5E7EB',
                borderColor: '#E5E7EB',
                borderWidth: 1,
              },
              datalabels: {
                color: '#E5E7EB',
                font: {
                  size: 10,
                  weight: 'bold',
                },
                formatter: (value) => `${formatNumber(value)}`,
                anchor: 'end',
                align: 'start',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AgentCapabilityDonutChart;
