import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

// Register the Chart.js plugins
Chart.register(ChartDataLabels);

const LocationDonutChart = ({ data, darkMode, title }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Process alarm data by location
    const locationCount = {};
    data.forEach((alarm) => {
      const location = alarm.location || 'Unknown'; // Assuming alarm object has a 'location' field
      if (locationCount[location]) {
        locationCount[location] += 1;
      } else {
        locationCount[location] = 1;
      }
    });

    const locations = Object.keys(locationCount);
    const counts = Object.values(locationCount);

    setChartData({
      labels: locations,
      datasets: [
        {
          label: 'Occurrences',
          data: counts,
          backgroundColor: darkMode
            ? ['#EF4444', '#F97316', '#EAB308', '#22C55E', '#3B82F6'] // Dark mode colors
            : ['#FCA5A5', '#FDBA74', '#FDE047', '#A7F3D0', '#93C5FD'], // Light mode colors
          borderColor: darkMode ? '#1F2937' : '#E5E7EB',
          borderWidth: 3,
          hoverOffset: 15,
        },
      ],
    });
  }, [data, darkMode]);

  // Format numbers for tooltips and datalabels
  const formatNumber = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'; // Format large numbers
    }
    return num.toFixed(0); // Format small numbers without decimals
  };

  return (
    <div
      className={`p-6 rounded-lg  ${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-900'} relative`}
      style={{ height: '400px', width: '100%', marginTop: '100px' }} // Added margin-top here
    >
      {/* Chart Title with Icon */}
      <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faBell} className="text-blue-400 text-2xl mr-2" />
          <h3 className="text-white text-xl font-semibold">Alarms by Location</h3>
        </div>

      <div style={{ height: '250px' }}>
        <Doughnut
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  color: darkMode ? '#E5E7EB' : '#1F2937',
                  font: {
                    size: 9,
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `${tooltipItem.label}: ${formatNumber(value)}`;
                  },
                },
                backgroundColor: darkMode ? '#1F2937' : '#FFFFFF',
                titleColor: darkMode ? '#E5E7EB' : '#1F2937',
                bodyColor: darkMode ? '#E5E7EB' : '#1F2937',
                borderColor: darkMode ? '#374151' : '#E5E7EB',
                borderWidth: 1,
              },
              datalabels: {
                color: darkMode ? '#E5E7EB' : '#1F2937',
                font: {
                  size: 10,
                  weight: 'bold',
                },
                formatter: (value) => `${formatNumber(value)}`,
                anchor: 'end',
                align: 'start',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default LocationDonutChart;
