import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faExclamationTriangle, faShieldAlt, faUser, faSignal, faBell } from '@fortawesome/free-solid-svg-icons';

const alertsData = [
  {
    "severity": "Red",
    "system": "Fire",
    "description": "Fire alarm triggered in Server Room 1.",
  },
  {
    "severity": "Orange",
    "system": "IT",
    "description": "Network instability detected in Server Room 2.",
  },
  {
    "severity": "Yellow",
    "system": "Security",
    "description": "Motion detected at Parking A entrance.",
  },
  {
    "severity": "Green",
    "system": "Fire",
    "description": "Fire alarm acknowledged and cleared at Parking B.",
  },
  {
    "severity": "Red",
    "system": "Fire",
    "description": "Smoke detected in Server Room 2.",
  },
];

const SystemAlerts = () => {
  const totalAlerts = alertsData.length;
  const redAlerts = alertsData.filter(alert => alert.severity === 'Red').length;
  const orangeAlerts = alertsData.filter(alert => alert.severity === 'Orange').length;
  const yellowAlerts = alertsData.filter(alert => alert.severity === 'Yellow').length;
  const greenAlerts = alertsData.filter(alert => alert.severity === 'Green').length;

  return (
    <div className="w-full h-full flex flex-col">

      <div className="bg-gray-800 rounded-lg shadow-lg h-full flex flex-col justify-between p-4">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faBell} className="text-blue-400 text-2xl mr-2" />
          <h3 className="text-white text-xl font-semibold">System Alerts</h3>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center bg-gray-700 rounded p-3 shadow hover:bg-gray-600 transition duration-200">
            <FontAwesomeIcon icon={faFire} className="text-red-400 text-xl mr-2" />
            <span className="text-white">Red Alerts: <span className="font-bold">{redAlerts}</span></span>
          </div>
          <div className="flex items-center bg-gray-700 rounded p-3 shadow hover:bg-gray-600 transition duration-200">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-orange-400 text-xl mr-2" />
            <span className="text-white">Orange Alerts: <span className="font-bold">{orangeAlerts}</span></span>
          </div>
          <div className="flex items-center bg-gray-700 rounded p-3 shadow hover:bg-gray-600 transition duration-200">
            <FontAwesomeIcon icon={faShieldAlt} className="text-yellow-400 text-xl mr-2" />
            <span className="text-white">Yellow Alerts: <span className="font-bold">{yellowAlerts}</span></span>
          </div>
          <div className="flex items-center bg-gray-700 rounded p-3 shadow hover:bg-gray-600 transition duration-200">
            <FontAwesomeIcon icon={faUser} className="text-green-400 text-xl mr-2" />
            <span className="text-white">Green Alerts: <span className="font-bold">{greenAlerts}</span></span>
          </div>
          <div className="flex items-center bg-gray-700 rounded p-3 shadow hover:bg-gray-600 transition duration-200">
            <FontAwesomeIcon icon={faSignal} className="text-blue-400 text-xl mr-2" />
            <span className="text-white">Total Alerts: <span className="font-bold">{totalAlerts}</span></span>
          </div>
        </div>
        <div className="mt-4 text-center text-gray-400 text-sm">
          <span>Last updated: {new Date().toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default SystemAlerts;
