import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const OverallResponseTimeMetric = () => {
  // New data for response times
  const data = [
    { task: "Task 1", value: 15 },
    { task: "Task 2", value: 12 },
    { task: "Task 3", value: 20 },
    { task: "Task 4", value: 18 },
    { task: "Task 5", value: 10 },
  ];

  // Prepare data for the chart
  const chartData = {
    labels: data.map(item => item.task), // Use task names as labels
    datasets: [
      {
        label: 'Response Time (Minutes)',
        data: data.map(item => item.value),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: data.map(item => (item.value > 18 ? 'rgba(255, 0, 0, 1)' : 'rgba(75, 192, 192, 1)')),
        pointBorderColor: data.map(item => (item.value > 18 ? 'rgba(255, 0, 0, 1)' : 'rgba(75, 192, 192, 1)')),
        pointRadius: data.map(item => (item.value > 18 ? 5 : 3)),
        pointHoverRadius: data.map(item => (item.value > 18 ? 8 : 5)),
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Tasks',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Response Time (Minutes)',
        },
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `Response Time: ${value} ${value > 18 ? '⚠️' : ''}`;
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-64">
      <h2 className="text-white text-lg font-bold mb-2 text-left">Overall Response Time Metric</h2>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default OverallResponseTimeMetric;
