import React from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const ShiftSchedule = () => {
  const data = {
    series: [{
      name: "Number of Agents",
      data: [10, 8, 7] // Fake data for Morning, Afternoon, Night shifts
    }],
    options: {
      chart: {
        type: 'bar',
        height: 250, // Adjusting to fit within the smaller div
        toolbar: {
          show: false, // Hides the chart toolbar for a cleaner look
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10, // Rounded corners for bars
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['Morning', 'Afternoon', 'Night'],
        labels: {
          style: {
            colors: '#fff', // White text for x-axis labels
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#fff', // White text for y-axis labels
          }
        }
      },
      grid: {
        borderColor: '#555', // Gridline color
        strokeDashArray: 4, // Dashed grid lines
      },
      responsive: [{
        breakpoint: 768,
        options: {
          chart: {
            height: 200, // Make the chart smaller on smaller screens
          },
          plotOptions: {
            bar: {
              columnWidth: '50%',
            },
          },
        }
      }]
    },
  };

  return (
    <div className="ShiftSchedule bg-gray-800 rounded-lg p-4 w-full h-64">
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faClock} className="text-blue-500 mr-2" />
        <h2 className="text-white text-lg">Shift Schedule Distribution</h2>
      </div>
      <div className="w-full h-full">
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          height="100%" // Set chart height to take up the full available space
        />
      </div>
    </div>
  );
};

export default ShiftSchedule;
